import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FilteredUserCollection from './FilteredUserCollection';
import InputFilter from './InputFilter';
import NormalFoilBadges from './NormalFoilBadges';
import useCollectionStore from '../state/collectionStore';
import ToggleButton from './ToggleButton';
import constants from '../constants';
import '../css/buttons.css';

const UserCollectionView = () => {
  const {
    history,
    fetchHistory,
    personalCollection,
    fetchPersonalCollection,
    updatePersonalCollection,
    modifyCopiesInCollection,
  } = useCollectionStore();
  const [collection, setCollection] = useState([]);
  const [set, setSet] = useState(`${constants.DEFAULT_SET}`);
  const [cardInput, setCardInput] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [operation, setOperation] = useState('add');
  const [file, setFile] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);	
  const [view, setView] = useState('collection');
  const [filteredCollection, setFilteredCollection] = useState([]);
  const [parsedCardInput, setParsedCardInput] = useState({ isValid: false, cardNumber: '', selectedSet: '', isFoil: false });
  const [justSubmitted, setJustSubmitted] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOpDropdownOpen, setOpDropdownOpen] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const [referenceFile, setReferenceFile] = useState(null);
  const [dreambornExportToggle, setDreambornExportToggle] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const [isAddMode, setIsAddMode] = useState(true);
  const sets = [
    { value: 'all', label: 'All Sets' },
    { value: '5', label: 'Set 5' },
    { value: '4', label: 'Set 4' },
    { value: '3', label: 'Set 3' },
    { value: '2', label: 'Set 2' },
    { value: '1', label: 'Set 1' },
  ];

  const operations = [
    { value: 'add', label: 'Add' },
    { value: 'remove', label: 'Remove' },
  ];
  useEffect(() => {
    setOperation(isAddMode ? 'add' : 'remove');
  },[isAddMode]);

  useEffect(() => {
    fetchHistory();
  }, []);

  const handleCloseClick = () => {
    setQuantity('1'); // Reset input if needed
    setCardInput(''); // Reset input if needed
  };
  const handleOperationChange = (operation) => {
    setQuantity('1');
    setOperation(operation);
    setOpDropdownOpen(false);
  }

  const handleQuantityChange = (e) => {
    e.preventDefault();
    setQuantity(e.target.value);
  }

  const handleModifyCardCopies = (e) => {
    e.preventDefault();
    const { cardNumber, selectedSet, isFoil } = parsedCardInput;
    modifyCopiesInCollection(cardNumber, selectedSet, isFoil, operation, quantity); 
    setJustSubmitted(true);
  };

  const handleCollectionItemClick = (cardId, cardNumber, cardSet, cardName, cardSurname, isFoil = false) => {
    const foilSuffix = isFoil ? 'f' : '';
    const input = `${cardNumber}${foilSuffix}-${cardSet} ${cardName} ${cardSurname}`;
    setCardInput(input);
  };

  const switchView = (view) => {
    setView(view);
    if (view === 'history') {
      fetchHistory();
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleReferenceFileChange = (e) => {
    setReferenceFile(e.target.files[0]);
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    setUploading(true);
    axios.post(`${constants.API_BASE_URL}/api/collection/import`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
	setModalOpen(false);
	setUploading(false);
        alert('Collection imported successfully, refresh the page to see the changes.');
	fetchPersonalCollection();
        //return axios.get(`${constants.API_BASE_URL}/api/collection`, { withCredentials: true });
      })
      .then(response => {
        setCollection(response.data);
      })
      .catch(error => {
        console.error('Error importing collection:', error);
      });
  };

  const handleExport = () => {
    axios.get(`${constants.API_BASE_URL}/api/collection/export`, {
      withCredentials: true,
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'collection.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Error exporting collection:', error);
      });
  };
  const handleDreambronExportClick = () => {
    setDreambornExportToggle(!dreambornExportToggle);
  }

  const handleComparisonExport = () => {
    const formData = new FormData();
    formData.append('referenceFile', referenceFile);

    axios.post(`${constants.API_BASE_URL}/api/collection/export-comparison`, formData, {
      withCredentials: true,
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'collection_comparison.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Error exporting comparison collection:', error);
      });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const toggleOperation = () => {
    setIsAddMode(!isAddMode);
  };
  const toggleView = () => {
    setView((prevView) => (prevView === 'collection' ? 'history' : 'collection'));
  };

  const handleSetSelect = (selectedSet) => {
    setSet(selectedSet);
    setDropdownOpen(false);
  };

  const handleExpandableClick = () => {
    setInputFocus(false); // This will re-render the input field
  };

  console.log(isAddMode);
  return (
    <div className="collection-view">
      <div className={`add-remove-form`}>

	  <div style={{ display: 'flex', margin: '0px', width: '100%' }} >
	  {!inputFocus && (
	    <>
          <div className="custom-select">
            <button className="select-button" onClick={toggleDropdown}>
              {sets.find((option) => option.value === set)?.label || "Select Set"}
            </button>
            {isDropdownOpen && (
              <ul className="dropdown-options">
                {sets.map((option) => (
                  <li
                    key={option.value}
                    className="dropdown-option"
                    onClick={() => handleSetSelect(option.value)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
	    <div>
	      <button
                className={`${isAddMode ? 'operation-button-add' : 'operation-button-remove'} `}
	        onClick={toggleOperation}
	      >
		{isAddMode ? '+' : '-'}
	      </button>
	    </div>
	    </>
	  )}
            {inputFocus && 
	    <div className="expandable-icon" onClick={handleExpandableClick}>
	      &#x25BC; {/* Down arrow icon, you can replace this with an actual icon if needed */}
	    </div>
	    }
	    <div>
	      <input
		type="number"
		value={quantity}
		onChange={handleQuantityChange}
		maxLength="3"
		className="number-input-field"
		required
	      />
	    </div>
	    <InputFilter
	      set={set}
	      collection={personalCollection}
	      filteredCollection={filteredCollection}
	      setFilteredCollection={setFilteredCollection}
	      setInputFocus={setInputFocus}
	      cardInput={cardInput}
	      setCardInput={setCardInput}
	      parsedCardInput={parsedCardInput}
	      setParsedCardInput={setParsedCardInput}
	      operation={operation}
	      isAddMode={isAddMode}
	      onSubmit={handleModifyCardCopies.bind(this)}
	    />
	  </div>
      </div>
      <div className="button-row">
        <div className="left-buttons">
          <button className="toggle-button" onClick={toggleView}>
            {view === 'collection' ? (
              <>
                <span className="small-text">Show</span> Activity
              </>
            ) : (
              <>
                <span className="small-text">Show</span> Collection
              </>
            )}
          </button>
        </div>
        <div className="right-buttons">
          <form onSubmit={handleFileUpload}>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <button type="button" onClick={() => setModalOpen(true)}>
              Import
            </button>
            <button type="button" onClick={() => setExportModalOpen(true)}>
              Export
            </button>
          </form>
        </div>
      {/* Modal for file upload */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Upload Collection File</h3>
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
            />
            {isUploading && (
              <div className="loading-message">Loading, please wait...</div>
            )}
            <div className="modal-buttons">
              <button onClick={() => setModalOpen(false)}>Cancel</button>
              <button onClick={handleFileUpload}>Submit</button>
            </div>
          </div>
        </div>
      )}
{isExportModalOpen && (
  <div className="modal-overlay">
    <div className="modal-content">
      <h3>Select Export Type</h3>
      <div className="export-options">
        <button 
          onClick={handleExport} 
          className="export-btn"
        >
          Standard Export
        </button>
        
        <div className="comparison-export">
          <button
            onClick={handleDreambronExportClick}
            className="export-btn"
          >
            Export for Dreamborn "Bulk Add"
          </button>
	  {dreambornExportToggle && 
	   <div className="dreamborn-input-container">
	        <label className="file-upload-label">
	          Provide a Dreamborn CSV file for comparison and generate the bulk export file:
		</label>
		<input
		    type="file"
		    id="referenceFileInput"
		    onChange={handleReferenceFileChange}
		    className="file-input"
		  />
		  <button
		    onClick={handleComparisonExport}
		    disabled={!referenceFile}
		    className="download-btn"
		  >
		    Download
		  </button>
	   </div>
	}
        </div>
        
        <div className="modal-buttons">
          <button onClick={() => setExportModalOpen(false)} className="cancel-btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}

      </div>
      {view === 'history' && (
        <div className="history-view">
          <ul>
            {history.map(item => (
              <li key={item.id}>
                {`[${new Date(item.activity_date).toLocaleString()}] ${item.operation}${item.comment ? ` : ${item.comment}` : ''}`}
              </li>
            ))}
          </ul>
        </div>
      )}
      {view === 'collection' && (
        <FilteredUserCollection
          filteredCollection={filteredCollection}
          handleCollectionItemClick={handleCollectionItemClick}
	  operation={operation}
	  quantity={quantity}
        />
      )}
    </div>
  );
};

export default UserCollectionView;

